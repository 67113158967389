import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { sendErrorToRollbar } from '../pages/_error'

const _instance = {}

const createApolloClient = (initialState = {}, cookie = null) => {
  if (_instance[cookie]) return _instance[cookie]

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          //define a custom merge function to update chatAiThreads array after deleting a thread to avoid "Cache data may be lost" warning (https://medium.com/@dexiouz/fix-cache-data-may-be-lost-when-replacing-the-getallposts-field-of-a-query-object-in-apollo-client-7973a87a1b43)
          chatAiThreads: {
            merge(_existing, incoming) {
              return incoming
            }
          }
        }
      }
    }
  }).restore(initialState)

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, path }) =>
        sendErrorToRollbar(
          `[GraphQL error]: Message: ${message}, Path: ${path}`
        )
      )

    if (networkError) sendErrorToRollbar(`[Network error]: ${networkError}`)
  })

  _instance[cookie] = new ApolloClient({
    ssrMode: true,
    link: from([
      errorLink,
      createUploadLink({
        uri: process.env.GRAPHQL_URL || 'https://app.worshiponline.com/graphql',
        credentials: 'include',
        headers: { cookie }
      })
    ]),
    connectToDevTools: true,
    resolvers: {},
    cache
  })

  return _instance[cookie]
}

export const staticPath = ({
  additionalPaths = async (_) => {
    return {}
  }
}) => {
  return async (ctx) => {
    const client = createApolloClient({})

    return {
      ...(await additionalPaths({ client, ctx }))
    }
  }
}

export const staticProps = ({
  additionalProps = async (_) => {
    return {}
  }
}) => {
  return async (ctx) => {
    const client = createApolloClient({})

    return {
      props: {
        ...(await additionalProps({ client, ctx }))
      },
      revalidate: 300
    }
  }
}

export default createApolloClient
